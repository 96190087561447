<script>
export default {
  created() {
    this.axios.post('/invitation-check', {
        code: this.$route.query.invitation
      })
      .then((res) => {
        this.showSuccessMsg(res.data.message);
      })
      .catch((err) => {
        this.showFailMsg(err.response.data.message);
      })
      .finally(() => {
        this.$router.push({name: 'login'})
      })
  }
}
</script>